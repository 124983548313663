import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Main from "./pages/Chat";
import Login from "./components/user/Login";
import SignUp from "./components/user/SignUp";
import "./App.css";
import ForgotPassword from "./components/user/ForgotPassword";
import Toast, { useToast } from "./components/user/Toast";
import Verify from "./components/user/Verify";
import ResetPassword from "./components/user/ResetPassword";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

function App() {

  return (
    <div className="overflow-hidden w-full h-screen relative flex ">
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="signup" element={<SignUp />} />
        <Route path="login" element={<Login />} />
        <Route path="verify/:token" element={<Verify />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="/chat/:chatId" element={<Main />} />
        <Route path="/chat" element={<Main />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
