import { Dispatch } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { getAllChats } from "../../features/chat/chatActions";
import { sendDataAuthenticated } from "../../utils/sendData";
import { useAppDispatch } from "../../app/hooks";

export async function createNewChatId(dispatch: Dispatch<any>, navigate: NavigateFunction) {
 
  const res = await sendDataAuthenticated({}, "chats/create-chat");
  dispatch(getAllChats(1));
  // const data = await res.json();
  // console.log(res)
  if (res) {
    const id = res.id;
    navigate(`/chat/${id}`);
  }
}

