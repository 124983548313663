import React, { useEffect, useState } from "react";
import { useAppDispatch, useTypedSelector } from "../../app/hooks";
import {
  getUser,
  logout,
  updateUserProfile,
} from "../../features/user/userActions";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";
import { sendDataAuthenticated } from "../../utils/sendData";
import { PencilIcon } from "@heroicons/react/24/solid";
import {
  DocumentCheckIcon,
  ShieldCheckIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import UpdatePassword from "../chats/UpdatePassword";
import UpdateProfile from "./UpdateProfile";

export default function ProfileSection({
  setOpen,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const [editVisible, setEditVisible] = React.useState(false);
  const dispatch = useAppDispatch();
  const {
    userData: data,
    isLoading,
    error,
  } = useTypedSelector((state) => state.userGet);
  useEffect(() => {
    dispatch(getUser());
  }, []);

  const deleteButtonHandler = () => {
    sendDataAuthenticated({}, "user/delete-user");
    localStorage.removeItem("user");
    // setOpen(false);
    navigate("/signup");
  };
  const [toggleProfileTab, setToggleProfileTab] = useState(true);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const handleContentClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };
  const handleBackdropClick = () => {
    setOpen(false);
  };
  return (
    <div className="relative z-50">
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75 "
        onClick={handleBackdropClick}
      >
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-[#212121] text-left shadow-xl  sm:my-8 w-full max-w-md sm:max-w-lg lg:max-w-xl">
              <section className="text-gray-600 " onClick={handleContentClick}>
                <div className="container px-5 py-12 mx-auto flex flex-wrap sm:flex-row flex-col  justify-center">
                  <div
                    className="w-full relative left-[80%] text-white text-xl bottom-5  px-4 cursor-pointer"
                    onClick={() => setOpen(false)}
                  >
                    <XMarkIcon className="w-6 h-6" /> 
                  </div>
                  <div className="flex mx-auto justify-center items-center flex-wrap mb-10 w-full">
                    <button
                      className={` sm:px-6 py-3 w-full sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none tracking-wider rounded-t cursor-pointer ${
                        toggleProfileTab
                          ? "border-indigo-500 text-indigo-500"
                          : ""
                      } `}
                      onClick={() => setToggleProfileTab(true)}
                    >
                      <UserIcon className="w-5 h-5 " />
                      Update Profile
                    </button>
                    <button
                      className={`bg-[#2f2f2f]  sm:px-6 py-3 w-full sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none border-gray-200 tracking-wider cursor-pointer ${
                        !toggleProfileTab
                          ? "border-indigo-500 text-indigo-500"
                          : ""
                      }`}
                      onClick={() => setToggleProfileTab(false)}
                    >
                      <ShieldCheckIcon className="w-5 h-5 mr-1" />
                      Update Password
                    </button>
                  </div>
                  {toggleProfileTab ? <UpdateProfile /> : <UpdatePassword />}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
