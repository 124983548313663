import React, { useEffect } from "react";
import { useAppDispatch, useTypedSelector } from "../../app/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/24/solid";
import Loader from "../user/Loader";
import { createNewChatId } from "./createNewChatId";
import { getChatsPerId } from "../../features/chat/chatActions";
import { toast } from "react-toastify";

export default function CreateChat() {
  const dispatch = useAppDispatch();
  const { user } = useTypedSelector((state) => state.userLogin);

  useEffect(() => {
    if (!user || user === null) {
      navigate("/login");
    }
  }, [user]);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const { chatId } = useParams();

  const { chats } = useTypedSelector((state) => state.getChatsPerId);
  const { messages } = useTypedSelector((state) => state.createStream);

  const createChatButtonHandler = async () => {
    setIsLoading(true);
    try {
      //  we don't want to create empty chats
      if (chatId) {
        // chatId from the url must be in the list of chats
        if (messages.length === 0) {
          console.log("messages are empty",messages.length);
          if (!chats.some((chat) => chat.chatId === chatId)) {
            console.log("cannot create chat with existing empty chat");
            toast.error("cannot create chat with existing empty chat");

            setIsLoading(false);
          }

          return;
        } else {
          await createNewChatId(dispatch, navigate);
        }
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      onClick={createChatButtonHandler}
      className="flex py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-500/10 transition-colors duration-200 text-white cursor-pointer text-sm mb-1 flex-shrink-0 border border-white/20"
    >
      {isLoading ? (
        <div className="grid place-items-center">
          <Loader />
        </div>
      ) : (
        <>
          <PlusIcon className="h-4 w-4" />
          <span> New Chat</span>
        </>
      )}
    </div>
  );
}
