import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "./Button";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useAppDispatch, useTypedSelector } from "../../app/hooks";
import { verifyUserEmail } from "../../features/user/userActions";
import { createNewChatId } from "../chats/createNewChatId";

export default function Verify() {
  const { token } = useParams();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { isSuccess, error } = useTypedSelector((s) => s.userVerifyEmail);
  useEffect(() => {
    if (token) {
      // console.log(token);
      let user = { accessToken: token };
      localStorage.setItem("user", JSON.stringify(user));

      dispatch(verifyUserEmail(token));
    }
  }, [token]);
  useEffect(() => {
    if (isSuccess) {
      toast.success("Email Verified");
      createNewChatId(dispatch, navigate);
    }
    if (error) {
      toast.error(error);
    }
  }, [isSuccess, error]);
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-[#212121] text-white overflow-y-auto text-center ">
      You are verified, now you can login
      <Link
        className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500 ml-2"
        to="/login"
      >
        Login
      </Link>
    </div>
  );
}
