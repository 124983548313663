import React, { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "./Loader";
import sendData, { sendDataAuthenticated } from "../../utils/sendData";
import { toast } from "react-toastify";
export default function ResetPassword() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    if (token) {
      localStorage.setItem("user", JSON.stringify({ accessToken: token }));
    } else {
      toast.error("Invalid token");
    }
  }, [location]);
  const submitHandler = async function submitHandler(
    event: FormEvent<HTMLFormElement>
  ) {
    event.preventDefault();
    setIsLoading(true);
    const formData = new FormData(event.target as HTMLFormElement);

    const password = formData.get("new-password") as string;
    const password2 = formData.get("new-password2") as string;

    if (!password || !password2) {
      return;
    }

    if (password !== password2) {
      toast.error("Password not match");
      setIsLoading(false);
      return;
    }

    const res = await sendDataAuthenticated(
      { password },
      "user/reset-password"
    );

    if (res) {
      toast.success(res.message);
    }
    if (res.success) {
      navigate("/login");
    }

    setIsLoading(false);
  };

  return (
    
      <div className="flex min-h-full flex-1 flex-col justify-center px-5 py-12 lg:px-8 bg-[#212121] text-white overflow-y-auto">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight ">
            Reset Password
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={submitHandler} className="space-y-6">
            <div>
              <label
                htmlFor="new-password"
                className="block text-sm font-medium leading-6 "
              >
                Enter new Password
              </label>
              <div className="mt-2">
                <input
                  id="new-password"
                  name="new-password"
                  type="password"
                  placeholder="Enter your new password"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="new-password2"
                className="block text-sm font-medium leading-6 "
              >
                Enter new Password Again
              </label>
              <div className="mt-2">
                <input
                  id="new-password2"
                  name="new-password2"
                  type="password"
                  placeholder="Enter password again"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="flex items-center justify-center">
              {isLoading ? (
                <Loader />
              ) : (
                <button
                  type="submit"
                  className="bg-blue-800 hover:bg-blue-900 text-white font-bold py-3 px-9 rounded-full text-center "
                >
                  Confirm
                </button>
              )}
            </div>
          </form>
        </div>
    </div>
  );
}
