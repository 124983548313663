import { useEffect, useState } from "react";
import ChatInput from "../components/chats/ChatInput";
import SideBar from "../components/chats/Sidebar";
import ScrollToBottom from "react-scroll-to-bottom";

import { PlusIcon, Bars3Icon } from "@heroicons/react/24/outline";
import { useAppDispatch, useTypedSelector } from "../app/hooks";
import { useNavigate, useParams, useRouteError } from "react-router-dom";
import Conversations from "../components/chats/Conversations";
import { getChatsPerId } from "../features/chat/chatActions";
import { initializeSocket } from "../websocket/socket";
import { Socket } from "socket.io-client";
import { updateOrAddAssistantMessage } from "../features/chat/chatSlice";
import { clientSocket } from "../websocket/socket";
import { toast } from "react-toastify";
import { error } from "console";
import { createNewChatId } from "../components/chats/createNewChatId";
export default function ChatPage() {
  const { chatId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { messages, error } = useTypedSelector((state) => state.createStream);
  const [toggleMenu, setToggleMenu] = useState(false);
  const {
    chats,
    isLoading,
    error: allChatsError,
  } = useTypedSelector((state) => state.getAllChats);

  useEffect(() => {
    if (!chatId) {
      createNewChatId(dispatch, navigate);
    }

    if (chatId) {
      dispatch(getChatsPerId(chatId));
    }
  }, [chatId]);

  useEffect(() => {
    if (!clientSocket) return console.log("no socket");
    clientSocket.on(chatId as string, handleSocketData);
    return () => {
      clientSocket.off(chatId, handleSocketData);
    };
  }, [chatId]);

  const handleSocketData = (messages: any) => {
    console.log("listening on this id");
    dispatch(updateOrAddAssistantMessage(messages));
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <>
      {/* sidebar */}
      <SideBar toggleMenu={toggleMenu} setToggleMenu={setToggleMenu} />
      <div className="flex max-w-full flex-1 flex-col">
        {/* header */}
        <div className="sticky top-0 z-10 flex items-center border-b border-white/20 bg-gray-800 pl-1 pt-1 text-gray-200 sm:pl-3 md:hidden">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 inline-flex h-10 w-10 items-center justify-center rounded-md hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white dark:hover:text-white"
            onClick={() => {
              setToggleMenu(!toggleMenu);
              console.log(toggleMenu);
            }}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <h1 className="flex-1 text-center text-base font-normal">New chat</h1>
        </div>

        <div className="relative h-full w-full transition-width flex flex-col overflow-hidden items-stretch flex-1">
          <div className="flex-1 overflow-hidden">
            <ScrollToBottom className="h-full bg-[#212121]  ">
              {messages?.length > 0 ? (
                <Conversations />
              ) : (
                <div className="py-10 relative w-full flex flex-col h-full bg-[#212121]">
                  <div className="flex items-center justify-center gap-2">
                    <div className="relative w-full md:w-1/2 lg:w-1/3 xl:w-1/4"></div>
                  </div>
                  <h1 className="text-2xl sm:text-4xl font-semibold text-center text-gray-200 dark:text-gray-600 flex gap-2 items-center justify-center h-screen">
                    Legal Bot
                  </h1>
                </div>
              )}
            </ScrollToBottom>
          </div>
          <ChatInput chatId={chatId as string} />
        </div>
      </div>
    </>
  );
}
