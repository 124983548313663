import React from "react";
import { Link } from "react-router-dom";
import Button from "../components/user/Button";

export default function Home() {
  return (
    <div className=" flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-[#212121] text-white overflow-y-auto">
      {/* introduction    */}
      <div className="info text-center p-10">
        <div className="mt-6 text-4xl font-extrabold text-white leading-snug tracking-tight break-words">
          Introducing the Legal AI, a cutting-edge tool designed to
          revolutionize the legal industry.
        </div>

        <div className="description font-thin text-white">
          It provides intelligent legal advice tailored to your specific needs,
          helping you navigate the legal landscape effectively.
        </div>
      </div>

      {/* login and signup */}

      <div className="flex gap-2  m-2  sm:mt-6 justify-center sm:gap-4 items-center  ">
        <Link to="/login">
          <Button title="Login" />
        </Link>
        <Link to="/signup">
          <Button title="Signup" />
        </Link>
      </div>
    </div>
  );
}
