import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useTypedSelector } from "../../app/hooks";
import { getUser, updateUserProfile } from "../../features/user/userActions";
import Loader from "./Loader";
import { updateProfileReset } from "../../features/user/userSlice";

export default function UpdateProfile() {
  const dispatch = useAppDispatch();
  const {
    userData: data,
    isLoading,
    error,
  } = useTypedSelector((state) => state.userGet);

  const {
    isSuccess,
    isLoading: updateProfileLoading,
    error: updateProfileError,
  } = useTypedSelector((state) => state.userUpdateProfile);
  useEffect(() => {
    dispatch(getUser());
  }, []);
  const [fullName, setFullName] = useState(data.fullName);

  const saveButtonHandler = () => {
    if (!fullName) {
      toast.error("Please enter a valid name");
      return;
    }

    dispatch(updateUserProfile({ fullName }));

    dispatch(getUser());
  };
  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (updateProfileError) {
      toast.error(updateProfileError);
    }
    if (isSuccess) {
      toast.success("Profile updated successfully");
    }
  }, [error, updateProfileError, isSuccess]);
  useEffect(() => {
    return () => {
      dispatch(updateProfileReset());
    };
  }, [dispatch]);
  return (
    <div>
      <div className="flex flex-col items-center w-full">
        <div className="w-full sm:w-auto">
          <label
            htmlFor="fullname"
            className="block text-sm font-medium leading-6 text-gray-300"
          >
            Fullname
          </label>
          <div className="relative mt-2 rounded-md shadow-sm">
            {isLoading && <Loader />}
            <input
              id="fullname"
              name="fullname"
              type="text"
              required
              placeholder="Enter full name"
              className="block w-full rounded-md bg-[#2f2f2f] border-0 py-1.5 pl-3 px-4 text-gray-300 ring-1 ring-inset outline-none ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              value={fullName}
              disabled={updateProfileLoading}
              onChange={(e) => setFullName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  saveButtonHandler();
                }
              }}
            />
          </div>
          {updateProfileLoading ? (
            <Loader />
          ) : (
            <button
              className="flex mt-4 sm:w-auto text-center w-full justify-center items-center text-white bg-indigo-600 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded"
              onClick={saveButtonHandler}
            >
              Update Profile
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
