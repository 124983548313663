import axios from "axios";
import { getBaseApi } from "../config/Environment";

const Api = getBaseApi();

const sendData = async (data: any, endPoint: string) => {
  try {
    const response = await fetch(`${Api}/${endPoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

export default sendData;

export const sendDataAuthenticated = async (
  data: any,
  endPoint: string,
  method: string = "POST"
) => {
  try {
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).accessToken : null;
    if (!token) {
      throw new Error("No token found");
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `${Api}/${endPoint}`;
    let response;

    if (method === "GET") {
      response = await axios.get(url, config);
    } else {
      response = await axios.post(url, data, config);
    }
    return response.data;
  } catch (error: any) {
    return error?.response?.message || error.message;
  }
};

export async function sendFormDataAuthenticated(userMessage: any) {
  const user = localStorage.getItem("user");
  const token = user ? JSON.parse(user).accessToken : null;

  // fix it please later
  const res = await axios.post(Api + `/chats/conversation/`, userMessage, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  if (res.data) {
    console.log("uploaded file request response data", res.data);
  }
}
