import { useState, FormEvent, useEffect, useRef } from "react";
import {
  CheckCircleIcon,
  DocumentDuplicateIcon,
  PaperAirplaneIcon,
  PaperClipIcon,
} from "@heroicons/react/24/outline";
import { Socket } from "socket.io-client";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useTypedSelector } from "../../app/hooks";
import {
  createChat,
  createStream,
  getChatsPerId,
} from "../../features/chat/chatActions";
import TextareaAutosize from "react-textarea-autosize";
import { Tooltip } from "react-tooltip";

import { toast } from "react-toastify";
import { clientSocket as socket } from "../../websocket/socket";
function ChatInput({ chatId }: { chatId: string }) {
  const [prompt, setPrompt] = useState("");
  const [file, setFile] = useState<any>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [fileName, setFileName] = useState("");

  // const newChatId = useParams();
  const { newChatInfo, isLoading, isSuccess } = useTypedSelector(
    (s) => s.createChat
  );

  const sendMessage = async (e: FormEvent) => {
    e.preventDefault();
    console.log("chat sent");
    try {
      if (!prompt) return;

      const input = prompt.trim();
      if (!input) return;

      if (!chatId) {
        await dispatch(createChat());
        const newChatId = newChatInfo.id;
        console.log(newChatId);

        if (!newChatId) {
          console.error("Failed to create new chat ID");
          return;
        }

        navigate("/chat/" + newChatId);
        chatId = newChatId;
      }

      setPrompt("");

      const formData = new FormData();

      if (file) {
        formData.set("fileName", file.name);
        formData.set("file", file);
        formData.set("isFileAttached", String(true));
      }
      formData.set("content", input);
      formData.set("chatId", chatId);

      console.log(file);

      dispatch(createStream(formData, socket));

      resetFileInput();
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const resetFileInput = () => {
    setFile(null);
    setIsFileUploaded(false);
    setFileName("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  const uploadFileHandler = async (e: any) => {
    e.preventDefault();

    if (!e.target.files || e.target.files.length === 0) {
      toast.error("No file selected");
      return;
    }

    const file = e.target.files[0];

    if (file.type !== "application/pdf") {
      toast.error("Only pdf files are allowed");
      resetFileInput();
      return;
    }
    if (file.size > 2 * 1024 * 1024) {
      // 2MB  1042157
      toast.error(
        "File too large. Max 2MB is allowed while your file is " +
          (file.size / 1024 / 1024).toFixed(2) +
          "MB"
      );
      resetFileInput();
      return;
    }

    setIsFileUploaded(true);
    setFileName(file.name);
    // const byteFile = await getAsByteArray(file);
    // console.log(byteFile)
    setFile(file);
  };

  const handlePaperClipClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  return (
    <div className=" absolute bottom-0 left-0 w-full dark:border-white/20 md:border-transparent md:dark:border-transparent bg-[#212121]">
      <form
        onSubmit={sendMessage}
        className="stretch mx-2 flex flex-row gap-3 last:mb-2 md:mx-4 md:last:mb-6 lg:mx-auto lg:max-w-2xl xl:max-w-3xl"
      >
        <div className="relative flex flex-col h-full flex-1 md:flex-col">
          <div className="flex py-2 flex-row w-full space-x-5 flex-grow md:py-2 md:pl-8 relative border border-black/10 dark:border-gray-900/50 bg-[#2f2f2f] dark:text-white rounded-[30px] shadow-[0_0_10px_rgba(0,0,0,0.10)] dark:shadow-[0_0_15px_rgba(0,0,0,0.10)] pl-5">
            <a className="flex items-center" id="attach">
              <PaperClipIcon
                className="w-5 h-5 cursor-pointer"
                onClick={handlePaperClipClick}
              />
              <input
                type="file"
                name="file"
                id="file"
                className="hidden"
                ref={fileInputRef}
                onChange={uploadFileHandler}
              />
              {isFileUploaded ? (
                <div className="ml-2 flex items-center">
                  <CheckCircleIcon className="w-5 h-5 text-green-500" />
                  <span className="ml-1 text-sm ">{fileName}</span>
                </div>
              ) : null}
            </a>
            <Tooltip
                anchorSelect="#attach"
                place="top"
                content="Attach PDF file max size 2MB"
              />
            <div className="w-[95%] pt-1 rounded-[30px]">
              <TextareaAutosize
                className="w-full m-0 bg-transparent p-0 pr-2 focus:ring-0 focus-visible:ring-0 dark:bg-transparent pl-2 md:pl-0 custom-scroll max-h-[150px] overflow-y-auto h-6 border-none  resize-none"
                placeholder="Send a message..."
                value={prompt}
                onChange={(e) => {
                  setPrompt(e.target.value);
                }}
                maxRows={7}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    sendMessage(e);
                  }
                }}
              />
            </div>
            <div className="flex w-[5%] tooltip-clip">
              <button
                disabled={!prompt}
                type="submit"
                className="absolute p-1 rounded-md bottom-3 md:bottom-3 pb-1 bg-transparent disabled:bg-gray-500 right-2 md:right-3 disabled:opacity-40"
              >
                <PaperAirplaneIcon className="h-4 w-4 -rotate-45 " />
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="px-3 pt-2 pb-3 bg-[#212121] text-center text-xs text-black/50 dark:text-white/50 md:px-4 md:pt-2 relative">
        <span className="text-[10px]">
          {" "}
          Chatbot may produce inaccurate information about people, places, or
          facts.
        </span>
      </div>
    </div>
  );
}

export default ChatInput;
