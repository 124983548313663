import React, { FormEvent, useState } from "react";
import sendData from "../../utils/sendData";
import { Link } from "react-router-dom";
import Loader from "./Loader";
import { toast } from "react-toastify";
export default function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = async (e: FormEvent) => {
    setIsLoading(true);
    e.preventDefault();

    const formData = new FormData(e.currentTarget as HTMLFormElement);

    const data = {
      email: formData.get("email"),
    };
    const res = await sendData(data, "user/forgot-password");
    toast.success(res.message);
    setIsLoading(false);
    console.log(res);
  };
  return (
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-[#212121] text-white overflow-y-auto">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight ">
            Forgot Password ?
          </h2>

          <p className="mt-2 text-center text-sm  leading-6 ">
            Enter your email and we will send you a link to reset your password
          </p>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={submitHandler} className="space-y-6">
            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 "
                >
                  Write Email Address
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                  required
                  autoComplete="email"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="flex items-center justify-center">
              {isLoading ? (
                <Loader />
              ) : (
                <button
                disabled={isLoading}
                  type="submit"
                  className="bg-blue-800 hover:bg-blue-900 text-white font-bold py-3 px-9 rounded-full text-center "
                >
                  Send Link
                </button>
              )}
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Login here?{" "}
            <Link
              to="/login"
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Login
            </Link>
          </p>
        </div>
      </div>
  );
}
