import React from "react";
import { useTypedSelector } from "../../app/hooks";
import Markdown from "react-markdown";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
import TypingLoadingIndicator from "./TypingLoadingIndicator";

export default function Conversations() {
  const { messages, isLoading } = useTypedSelector(
    (state) => state.createStream
  );

  return (
    <div className="flex flex-col items-center text-sm py-10">
      {messages.map((message, index) => (
        <React.Fragment key={index}>
          {message.role === "user" ? (
            <>
              <UserConversation
                content={message.content  }
                isFileAttached={message.isFileAttached}
                fileName={message.fileName}
              />
            </>
          ) : (
            <>
              <AIConversation content={message.content} isLoading={false} />
            </>
          )}
        </React.Fragment>
      ))}
      <div className="w-full flex">
        {isLoading ? <AIConversation content="" isLoading={true} /> : null}
      </div>
      <div className="w-full h-32 md:h-48 flex-shrink-0 bg-[#212121]"></div>
    </div>
  );
}

function UserConversation({
  content,
  isFileAttached,
  fileName,
}: {
  content: string;
  isFileAttached?: boolean;
  fileName?: string;
}) {
  return (
    <div
      className='group w-full text-gray-800 dark:text-gray-100 border-b border-black/10 bg-[#212121] dark:border-gray-900/50"
        }'
    >
      <div className="text-base gap-4 md:gap-6 md:max-w-md lg:max-w-xl xl:max-w-3xl flex lg:px-0 m-auto w-full justify-end md:pl-0 pl-0 md:pr-0 pr-5">
        <div className="inline-flex flex-row gap-5 md:gap-6 md:max-w-2xl p-4 md:py-6 lg:px-8 min-w-[25%] justify-start  flex-row-reverse bg-[#2f2f2f] rounded-xl my-1">
          <div className="w-8 flex flex-col relative items-end">
            <div className="relative h-8 w-8 p-1 rounded-[50%] text-white flex items-center justify-center bg-black/75 text-opacity-100r">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 1024 1024"
                className="h-5 w-5 text-white"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M858.5 763.6a374 374 0 0 0-80.6-119.5 375.63 375.63 0 0 0-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 0 0-80.6 119.5A371.7 371.7 0 0 0 136 901.8a8 8 0 0 0 8 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 0 0 8-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z"></path>
              </svg>
            </div>
          </div>
          <div className="relative flex w-[calc(100%-50px)] flex-col gap-1 md:gap-3 ">
            <div className="flex flex-grow flex-col gap-3">
              <div className="min-h-15 flex flex-col items-start gap-4 whitespace-pre-wrap break-words">
                <div className="markdown prose break-words dark:prose-invert dark text-end w-full">
                  <p>
                    <div className="w-full text-white">
                      <p>{content}</p>
                    </div>
                  </p>
                </div>
              </div>
            </div>
            {isFileAttached ? (
              <a className="flex justify-start relative top-8 file-attached  w-fit items-center gap-2 border p-1 rounded-md bg-[#2f2f2f] border-neutral-400 ">
                <DocumentDuplicateIcon className="w-7 h-7 " />
                <p className="text-neutral-300 text-sm">{fileName || ""}</p>
              </a>
            ) : null}
            <Tooltip anchorSelect=".file-attached" place="top">
              You attached a file to this message
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}

function AIConversation({
  content,
  isLoading,
}: // isLoading,
{
  content: string;
  isLoading: boolean;
}) {
  return (
    <div
      className={`group w-full text-gray-800 dark:text-gray-100 border-b border-black/10 bg-[#212121] dark:border-gray-900/50
      }`}
    >
      <div className="text-base md:max-w-md lg:max-w-xl xl:max-w-3xl flex lg:px-0 m-auto w-full justify-end md:pl-0 pl-0 md:pr-0 pr-5">
        <div className="inline-flex md:max-w-2xl p-4 md:py-6 min-w-[25%] justify-start  lg:max-w-xl xl:max-w-3xl w-full">
          <div className="w-8 relative items-end">
            <div className="relative h-8 p-1 rounded-[50%] text-white justify-center bg-black/75 text-opacity-100r">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                role="img"
                viewBox="0 0 24 24"
                className="h-5 text-white"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M22.2819 9.8211a5.9847 5.9847 0 0 0-.5157-4.9108 6.0462 6.0462 0 0 0-6.5098-2.9A6.0651 6.0651 0 0 0 4.9807 4.1818a5.9847 5.9847 0 0 0-3.9977 2.9 6.0462 6.0462 0 0 0 .7427 7.0966 5.98 5.98 0 0 0 .511 4.9107 6.051 6.051 0 0 0 6.5146 2.9001A5.9847 5.9847 0 0 0 13.2599 24a6.0557 6.0557 0 0 0 5.7718-4.2058 5.9894 5.9894 0 0 0 3.9977-2.9001 6.0557 6.0557 0 0 0-.7475-7.0729zm-9.022 12.6081a4.4755 4.4755 0 0 1-2.8764-1.0408l.1419-.0804 4.7783-2.7582a.7948.7948 0 0 0 .3927-.6813v-6.7369l2.02 1.1686a.071.071 0 0 1 .038.052v5.5826a4.504 4.504 0 0 1-4.4945 4.4944zm-9.6607-4.1254a4.4708 4.4708 0 0 1-.5346-3.0137l.142.0852 4.783 2.7582a.7712.7712 0 0 0 .7806 0l5.8428-3.3685v2.3324a.0804.0804 0 0 1-.0332.0615L9.74 19.9502a4.4992 4.4992 0 0 1-6.1408-1.6464zM2.3408 7.8956a4.485 4.485 0 0 1 2.3655-1.9728V11.6a.7664.7664 0 0 0 .3879.6765l5.8144 3.3543-2.0201 1.1685a.0757.0757 0 0 1-.071 0l-4.8303-2.7865A4.504 4.504 0 0 1 2.3408 7.872zm16.5963 3.8558L13.1038 8.364 15.1192 7.2a.0757.0757 0 0 1 .071 0l4.8303 2.7913a4.4944 4.4944 0 0 1-.6765 8.1042v-5.6772a.79.79 0 0 0-.407-.667zm2.0107-3.0231l-.142-.0852-4.7735-2.7818a.7759.7759 0 0 0-.7854 0L9.409 9.2297V6.8974a.0662.0662 0 0 1 .0284-.0615l4.8303-2.7866a4.4992 4.4992 0 0 1 6.6802 4.66zM8.3065 12.863l-2.02-1.1638a.0804.0804 0 0 1-.038-.0567V6.0742a4.4992 4.4992 0 0 1 7.3757-3.4537l-.142.0805L8.704 5.459a.7948.7948 0 0 0-.3927.6813zm1.0976-2.3654l2.602-1.4998 2.6069 1.4998v2.9994l-2.5974 1.4997-2.6067-1.4997Z"></path>
              </svg>
            </div>
          </div>
          <div className="relative flex-col gap-1 md:gap-3 ">
            <div className="flex flex-grow gap-3">
              <div className="min-h-15 items-start whitespace-pre-wrap break-words">
                <div className="markdown prose break-words dark:prose-invert dark w-full">
                  <p>
                    <div className="w-full text-white">
                      {!isLoading ? (
                        <Markdown>{content}</Markdown>
                      ) : (
                        <TypingLoadingIndicator />
                      )}
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
