import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const createChatSlice = createSlice({
  name: "create-chat",
  initialState: {
    isLoading: false,
    error: null,
    newChatInfo: {
      id: "",
      userId: "",
      chatName: "",
      createdAt: "",
      updatedAt: "",
    },
    isSuccess: false,
  },
  reducers: {
    createChatRequest(state) {
      state.isLoading = true;
      state.error = null;
    },
    // user login success
    createChatSuccess(state, action) {
      state.isLoading = false;
      state.newChatInfo = action.payload;
      state.isSuccess = true;
    },
    createChatError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { createChatRequest, createChatSuccess, createChatError } =
  createChatSlice.actions;

const initialState = {
  totalCount: 0,
  totalPage: 0,
  currentPage: "1",
  next: {
    page: 2,
    limit: 10,
  },
  paginatedData: [
    {
      id: "29843cef-52a8-4c0c-a836-68d58d937cea",
      userId: "afe497b2-a6c4-4d53-ac96-2ef556311582",
      chatName: "New chat",
      createdAt: "2024-08-12T18:25:23.642Z",
      updatedAt: "2024-08-12T18:25:23.642Z",
    },
    {
      id: "06f93f92-4fc1-41f9-9d8e-df65e78e11b0",
      userId: "afe497b2-a6c4-4d53-ac96-2ef556311582",
      chatName: "New chat",
      createdAt: "2024-08-09T18:31:31.894Z",
      updatedAt: "2024-08-09T18:31:31.894Z",
    },
  ],
  currentCountPerPage: 6,
  range: 10,
};

export const getAllChatsSlice = createSlice({
  name: "get-all-chats",

  initialState: {
    isLoading: false,
    error: null,
    chats: initialState,
    isSuccess: false,
  },
  reducers: {
    getAllChatsRequest(state) {
      state.isLoading = true;
      state.error = null;
    },
    // user login success
    getAllChatsSuccess(state, action) {
      state.isLoading = false;
      state.chats = action.payload;
      state.isSuccess = true;
    },
    getAllChatsError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { getAllChatsRequest, getAllChatsSuccess, getAllChatsError } =
  getAllChatsSlice.actions;

export const getChatsPerIdSlice = createSlice({
  name: "get-chats-per-id",
  initialState: {
    isLoading: false,
    error: null,
    chats: [
      {
        id: "",
        chatId: "",
        role: "user",
        content: "",
        createdAt: "",
        updatedAt: "",
      },
      {
        id: "",
        chatId: "",
        role: "assistant",
        content:
          "",
        createdAt: "",
        updatedAt: "",
      },
    ],
    isSuccess: false,
  },
  reducers: {
    getChatsPerIdRequest(state) {
      state.isLoading = true;
      state.error = null;
    },

    getChatsPerIdSuccess(state, action) {
      state.isLoading = false;
      state.chats = action.payload;
      state.isSuccess = true;
    },
    getChatsPerIdError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getChatsPerIdRequest,
  getChatsPerIdSuccess,
  getChatsPerIdError,
} = getChatsPerIdSlice.actions;

export const deleteChatSlice = createSlice({
  name: "delete-chat",
  initialState: {
    isLoading: false,
    error: null,
    chats: {},
    isSuccess: false
    
  },
  reducers: {
    deleteChatRequest(state) {
      state.isLoading = true;
      state.error = null;
    },
    // user login success
    deleteChatSuccess(state, action) {
      state.isLoading = false;
      state.chats = action.payload;
      state.isSuccess = true;
    },
    deleteChatError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { deleteChatRequest, deleteChatSuccess, deleteChatError } =
  deleteChatSlice.actions;

export const createStreamSlice = createSlice({
  name: "create-stream",
  initialState: {
    isLoading: false,
    error: null,
    chatId: null,
    messages: [
      {
        role: "user",
        content: " ",
        isFileAttached:false,
        fileName:''
      },
      {
        role: "assistant",
        content: "",
      
      },
    ],
    isSuccess: false,
  },
  reducers: {
    createStreamRequest(state) {
      state.isLoading = true;
      state.error = null;
    },
    
    createStreamSuccess(state, action) {
      state.isLoading = false;
      state.isSuccess = true;
      state.messages = action.payload;
    },
    createStreamError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    addMessage(state, action) {
      state.messages.push(action.payload);
      state.isLoading = true;
    
    },
    updateOrAddAssistantMessage(state, action) {
     
      state.isLoading = false;
      const lastMessage = state.messages[state.messages.length - 1];
      if (lastMessage && lastMessage.role === "assistant") {
         lastMessage.content += action.payload;
        state.isLoading = false;
     
      } else {
        state.messages.push({
          role: "assistant",
          content: action.payload,
        });
          state.isLoading = false;
      }
    },
  },
});

export const {
  createStreamRequest,
  createStreamSuccess,
  createStreamError,
  addMessage,
  updateOrAddAssistantMessage,
} = createStreamSlice.actions;
export const regenerateStreamSlice = createSlice({
  name: "regenerate-stream",
  initialState: {
    isLoading: false,
    error: null,
    chatId: null,
    messages: [
      {
        role: "user",
        content: " Hello , this is a default text for testing purpose.",
      },
      {
        role: "assistant",
        content: "okay. seems fine.",
      },
    ],
    isSuccess: false,
  },
  reducers: {
    regenerateStreamRequest(state) {
      state.isLoading = true;
      state.error = null;
    },
    // user login success
    regenerateStreamSuccess(state, action) {
      state.isLoading = false;
      state.isSuccess = true;
      state.messages = action.payload;
    },
    regenerateStreamError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    addMessageRegenerate(state, action) {
      state.messages.push(action.payload);
    },
    updateOrAddAssistantMessageRegenerate(state, action) {
      const lastMessage = state.messages[state.messages.length - 1];
      if (lastMessage && lastMessage.role === "assistant") {
        // to prevent duplication error
        // if (!lastMessage.content.includes(action.payload) ) {
        lastMessage.content += action.payload;
        // }
      } else {
        state.messages.push({
          role: "assistant",
          content: action.payload,
        });
      }
    },
  },
});

export const {
  regenerateStreamRequest,
  regenerateStreamSuccess,
  regenerateStreamError,
  addMessageRegenerate,
  updateOrAddAssistantMessageRegenerate,
} = regenerateStreamSlice.actions;

export const renameChatSlice = createSlice({
  name: "rename-chat",
  initialState: {
    isLoading: false,
    error: null,
    chats: {},
    isSuccess: false,
  },
  reducers: {
    renameChatRequest(state) {
      state.isLoading = true;
      state.error = null;
    },

    renameChatSuccess(state, action) {
      state.isLoading = false;
      state.chats = action.payload;
      state.isSuccess = true;
    },
    renameChatError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { renameChatRequest, renameChatSuccess, renameChatError } =
  renameChatSlice.actions;

export const fileAttachSlice = createSlice({
  name: "file-attach",
  initialState: {
    chatId:null,
    conversationId: null,
    isFileAttached: false,
  },
  reducers: {
    fileAttach(state, action) {
      state.chatId = action.payload;
      state.conversationId = action.payload;
      state.isFileAttached = true;
    },
  },
});
export const { fileAttach } = fileAttachSlice.actions;
