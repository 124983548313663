import { useState, useEffect, useRef } from "react";
import ProfileSection from "./ProfileSection";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";

export default function Profile() {
  const [open, setOpen] = useState(false);

  const openProfileModel = () => {
    setOpen(true);
  };

  return (
    <>
      <div
        className="flex py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-500/10 text-white cursor-pointer text-sm"
        onClick={openProfileModel}
      >
        <Cog6ToothIcon className="h-4 w-4" />
        <div>Settings</div>
      </div>
      {open && <ProfileSection setOpen={setOpen} />}
    </>
  );
}
