import axios, { AxiosError } from "axios";

import {
  loginRequest,
  loginFail,
  loginSuccess,
  logoutAction,
  registerRequest,
  registerFail,
  registerSuccess,
  getUserRequest,
  getUserSuccess,
  getUserFail,
  updateProfileRequest,
  updateProfileSuccess,
  updateProfileFail,
  registerResetError,
  loginResetError,
  updatePasswordRequest,
  updatePasswordSuccess,
  updatePasswordFail,
  verifyEmailRequest,
  verifyEmailSuccess,
  verifyEmailFail,
  updatePasswordReset,
} from "./userSlice";
import type {
  ProfileArgs,
  UserLoginCredentials,
  UserRegisterCredentials,
} from "../../types/user";
import { Dispatch } from "redux";
import { RootState } from "../../app/store";

import { getBaseApi } from "../../config/Environment";

const Api = getBaseApi();
export const login =
  (user: UserLoginCredentials) => async (dispatch: Dispatch) => {
    try {
      dispatch(loginResetError());
      dispatch(loginRequest());
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const { data } = await axios.post(Api + `/user/login`, user, config);
      dispatch(loginSuccess(data));

      localStorage.setItem("user", JSON.stringify(data));
    } catch (error: any) {
      dispatch(loginFail(error.response && error.response.data.error));
    }
  };

export const logout = () => (dispatch: Dispatch) => {
  localStorage.removeItem("user");
  dispatch(logoutAction());
};

export const register =
  (user: UserRegisterCredentials) => async (dispatch: Dispatch) => {
    try {
      dispatch(registerResetError());
      dispatch(registerRequest());
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await axios.post(Api + "/user/signup", user, config);

      dispatch(registerSuccess(data));
    } catch (error: any) {
      dispatch(registerFail(error.response && error.response.data.error));
    }
  };

export const getUser =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(getUserRequest());
      const {
        userLogin: { user },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user?.accessToken}`,
        },
      };

      const { data } = await axios.get(Api + "/user/get-user", config);
      dispatch(getUserSuccess(data));
    } catch (error) {
      dispatch(getUserFail(error as AxiosError));
    }
  };

export const updateUserProfile =
  (userArg: ProfileArgs) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(updateProfileRequest());

      const {
        userLogin: { user },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user?.accessToken}`,
        },
      };

      const res = await axios.post(
        Api + `/user/update-profile`,
        userArg,
        config
      );

      dispatch(updateProfileSuccess(res.data));
    } catch (error: any) {
      dispatch(updateProfileFail(error.response && error.response.data));
    }
  };

export const updateUserPassword =
  (data: { password: string; oldPassword: string }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(updatePasswordRequest());
      const {
        userLogin: { user },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user?.accessToken}`,
        },
      };
      const res = await axios.post(Api + `/user/update-password`, data, config);
      dispatch(updatePasswordSuccess(res.data));

      // dispatch(updatePasswordReset());
    } catch (error: any) {
      dispatch(
        updatePasswordFail(error.response && error.response.data?.error)
      );
    }
  };
export const verifyUserEmail =
  (token: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(verifyEmailRequest());
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await axios.post(
        Api + `/user/verify-email/`,
        {},
        config
      );
      dispatch(verifyEmailSuccess(data));
    } catch (error: any) {
      dispatch(verifyEmailFail(error.message));
    }
  };
