// DUCKS pattern

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { AccessCredentials } from "../../types/user";

const UserFromStorage: AccessCredentials | null =
  localStorage.getItem("user") !== null
    ? JSON.parse(localStorage.getItem("user")!)
    : null;

export const userLoginSlice = createSlice({
  name: "login",
  initialState: {
    isLoading: false,
    error: null,
    user: UserFromStorage,
    isSuccess: false,
  },

  reducers: {
    // user login pending/request
    loginRequest(state) {
      state.isLoading = true;
      state.error = null;
    },
    // user login success
    loginSuccess(state, action: PayloadAction<AccessCredentials>) {
      state.isLoading = false;
      state.user = action.payload;
      state.isSuccess = true;
    },

    // user login failed

    loginFail(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    loginResetError(state) {
      state.error = null;
    },
    // user logout
    logoutAction(state) {
      state.user = null;
    },
  },
});

export const {
  loginRequest,
  loginFail,
  loginSuccess,
  logoutAction,
  loginResetError,
} = userLoginSlice.actions;

export const userRegisterSlice = createSlice({
  name: "register",
  initialState: {
    isLoading: false,
    isSuccess: false,
    user: {},
    error: null,
  },

  reducers: {
    // user register pending/request
    registerRequest(state) {
      state.isLoading = true;
      state.error = null;
    },
    // user register success
    registerSuccess(state) {
      state.isLoading = false;
      state.isSuccess = true;
    },
    registerResetError(state) {
      state.error = null;
    },
    registerFail(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  registerRequest,
  registerFail,
  registerSuccess,
  registerResetError,
} = userRegisterSlice.actions;

// user forgot password slice
export const userForgotPasswordSlice = createSlice({
  name: "forgot-password",
  initialState: {
    isLoading: false,
    isSuccess: false,
    error: null,
  },

  reducers: {
    // user register pending/request
    userForgotPasswordRequest(state) {
      state.isLoading = true;
      state.error = null;
    },
    // user register success
    userForgotPasswordSuccess(state) {
      state.isLoading = false;
      state.isSuccess = true;
    },

    // user login failed
    userForgotPasswordFail(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  userForgotPasswordRequest,
  userForgotPasswordFail,
  userForgotPasswordSuccess,
} = userForgotPasswordSlice.actions;
const initialStateUserData = {
  id: "",
  accountStatus: "",
  avatar: null,
  email: "",
  password: "",
  fullName: "",
  verified: false,
  createdAt: "",
  updatedAt: "",
};

export const userGetSlice = createSlice({
  name: "get-user",
  initialState: {
    isLoading: false,
    userData: initialStateUserData,
    error: null,
  },
  reducers: {
    getUserRequest(state) {
      state.isLoading = true;
      state.error = null;
    },
    getUserSuccess(state, action) {
      state.isLoading = false;
      state.userData = action.payload;
    },
    getUserFail(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});
export const { getUserRequest, getUserFail, getUserSuccess } =
  userGetSlice.actions;

// update user info
export const userUpdateProfileSlice = createSlice({
  name: "update-profile",
  initialState: {
    updateUser: null,
    isLoading: false,
    error: null,
    isSuccess: false,
  },
  reducers: {
    updateProfileRequest(state) {
      state.isLoading = true;
      state.error = null;
    },
    updateProfileSuccess(state, action) {
      state.isLoading = false;
      state.isSuccess = true;
      state.updateUser = action.payload;
    },
    updateProfileFail(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateProfileReset(state) {
      state.isSuccess = false;
      state.error = null;
      state.isLoading = false;
      state.updateUser = null;
    },
  },
});
export const { updateProfileRequest, updateProfileFail, updateProfileSuccess , updateProfileReset} =
  userUpdateProfileSlice.actions;

export const userUpdatePasswordSlice = createSlice({
  name: "update-password",
  initialState: {
    isLoading: false,
    error: null,
    isSuccess: false,
    response: {
      success: false,
      message: "",
    },
  },
  reducers: {
    updatePasswordRequest(state) {
      state.isLoading = true;
      state.error = null;
      state.isSuccess = false;
    },
    updatePasswordSuccess(state, action) {
      state.isLoading = false;
      state.isSuccess = true;
      state.response = action.payload;
    },
    updatePasswordFail(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    updatePasswordReset(state) {
      state.isLoading = false;
      state.error = null;
      state.isSuccess = false;
      state.response = {
        success: false,
        message: "",
      };
    },
  },
});
export const {
  updatePasswordRequest,
  updatePasswordFail,
  updatePasswordSuccess,
  updatePasswordReset,
} = userUpdatePasswordSlice.actions;

export const userVerifyEmailSlice = createSlice({
  name: "verify-email",
  initialState: {
    isLoading: false,
    error: null,
    isSuccess: false,
  },
  reducers: {
    verifyEmailRequest(state) {
      state.isLoading = true;
      state.error = null;
    },
    verifyEmailSuccess(state, action) {
      state.isLoading = false;
      state.isSuccess = true;
    },
    verifyEmailFail(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});
export const { verifyEmailRequest, verifyEmailSuccess, verifyEmailFail } =
  userVerifyEmailSlice.actions;
