import { io, Socket } from "socket.io-client";
import { getBaseApi } from "../config/Environment";

export const initializeSocket = () => {
  let Api = getBaseApi();
  let socket: Socket | null = null;
 
  if (!socket) {
    socket = io(Api, {
      withCredentials: true,
      transports: ["websocket"],
    });

    socket.on("connect", () => {
      console.log("Connected to WebSocket server:", socket?.id);
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from WebSocket server");
    });

    socket.on("error", (error: any) => {
      console.error("Socket error:", error);
    });
  }
  return socket;
};

export const clientSocket = initializeSocket();
