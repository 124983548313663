import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useAppDispatch, useTypedSelector } from "../../app/hooks";
import { updateUserPassword } from "../../features/user/userActions";
import Loader from "../user/Loader";
import { updatePasswordReset } from "../../features/user/userSlice";

export default function UpdatePassword() {
  const dispatch = useAppDispatch();
  const { isLoading, error, response, isSuccess } = useTypedSelector(
    (state) => state.userUpdatePassword
  );

  const passwordFormSubmitHandler = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    const formData = new FormData(event.target as HTMLFormElement);
    const oldPassword = formData.get("oldPassword") as string;
    const newPassword = formData.get("newPassword") as string;

    // console.log(oldPassword, newPassword);

    if (!oldPassword || !newPassword) {
      return;
    }
    if (oldPassword === newPassword) {
      return toast.error("New password cannot be same as old password");
    }
    dispatch(updateUserPassword({ password: newPassword, oldPassword }));
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (isSuccess) {
      toast.success(String(response?.message));
    }
  }, [error, response, isSuccess]);
  useEffect(() => {
    return () => {
      dispatch(updatePasswordReset());
    };
  }, [dispatch]);
  return (
    <div>
      <form
        onSubmit={passwordFormSubmitHandler}
        className="flex flex-col items-center w-full"
      >
        <div className="w-full sm:w-auto">
          <label
            className="block text-sm font-medium leading-6 text-gray-300"
            htmlFor="oldPassword"
          >
            Old Password
          </label>
          <div className="relative mt-2 rounded-md shadow-sm">
            <input
              type="password"
              name="oldPassword"
              id="oldPassword"
              required
              disabled={isLoading}
              placeholder="enter old password"
              className="block w-full rounded-md bg-[#2f2f2f] border-0 py-1.5 pl-3 px-3 outline-none text-gray-300 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="mt-3 w-full sm:w-auto">
          <label
            htmlFor="newPassword"
            className="block text-sm font-medium leading-6 text-gray-300"
          >
            New Password
          </label>
          <div className="relative mt-2 rounded-md shadow-sm">
            <input
              type="password"
              name="newPassword"
              id="newPassword"
              required
              disabled={isLoading}
              placeholder="enter new password"
              className="block w-full rounded-md bg-[#2f2f2f] border-0 py-1.5 pl-3 px-3 outline-none text-gray-300 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <button
              type="submit"
              className="flex mt-4 justify-center items-center  sm:w-auto text-center w-full text-white bg-indigo-600 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded"
            >
              Update Password
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
