import { Dispatch } from "@reduxjs/toolkit";
import { sendDataAuthenticated } from "../../utils/sendData";
import {
  createChatRequest,
  createChatSuccess,
  createChatError,
  getAllChatsRequest,
  getAllChatsSuccess,
  getAllChatsError,
  renameChatRequest,
  renameChatSuccess,
  renameChatError,
  deleteChatRequest,
  deleteChatSuccess,
  deleteChatError,
  createStreamRequest,
  createStreamSuccess,
  createStreamError,
  addMessage,
  updateOrAddAssistantMessage,
  getChatsPerIdRequest,
  getChatsPerIdSuccess,
  getChatsPerIdError,
  fileAttach,
} from "./chatSlice";
import { Socket } from "socket.io-client";

import { sendFormDataAuthenticated } from "../../utils/sendData";

export const createChat = () => async (dispatch: Dispatch) => {
  try {
    dispatch(createChatRequest());
    const res = await sendDataAuthenticated({}, "chats/create-chat");
    dispatch(createChatSuccess(res));
  } catch (error: any) {
    dispatch(createChatError(error.response.data));
  }
};

export const getAllChats = (pageNo: number) => async (dispatch: Dispatch) => {
  try {
    dispatch(getAllChatsRequest());
    const res = await sendDataAuthenticated(
      {},
      `chats/get-all-chats/${pageNo}`,
      "GET"
    );
    dispatch(getAllChatsSuccess(res));
  } catch (error: any) {
    dispatch(getAllChatsError(error.message));
  }
};
export const getChatsPerId = (chatId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(getChatsPerIdRequest());
    const res = await sendDataAuthenticated(
      {},
      `chats/get-all-chats-conversation/${chatId}`,
      "GET"
    );

    dispatch(getChatsPerIdSuccess(res));
    const messages = res.map((message: any) => {
      return { role: message.role, content: message.content , isFileAttached:message.isFileAttached, fileName:message.fileName};
    });

    dispatch(createStreamSuccess(messages));
  } catch (error: any) {
    dispatch(getChatsPerIdError(error?.message));
  }
};

export const renameChat =
  ({ chatId, chatName }: { chatId: string; chatName: string }) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(renameChatRequest());
      const res = await sendDataAuthenticated(
        { chatId, chatName },
        "chats/update-chat-name"
      );
      dispatch(renameChatSuccess(res.data));
    } catch (error: any) {
      dispatch(renameChatError(error.response.data));
    }
  };

export const deleteChat = (chatId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(deleteChatRequest());
    const res = await sendDataAuthenticated({ chatId }, "chats/delete-chat");
    dispatch(deleteChatSuccess(res.data));
  } catch (error: any) {
    dispatch(deleteChatError(error.response.data));
  }
};



export const createStream =
  (userMessage: FormData , socket: Socket | null) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(createStreamRequest());
      let content = userMessage.get("content");
      let isFileAttached = Boolean(userMessage.get("isFileAttached"))
      let fileName = userMessage.get("fileName")


      dispatch(addMessage({ role: "user", content , isFileAttached, fileName}));

      if (!socket || !socket.connected) {
        console.error("No socket connection available");
        dispatch(createStreamError("No socket connection available"));
        return null;
      }

      await sendFormDataAuthenticated(userMessage);
    } catch (error) {
      console.error("Error creating stream:", error);
      dispatch(createStreamError("Failed to create stream"));
      return null;
    }
  };

export const setFileAttach =
  (chatId: string, conversationId:string, isFileAttached: boolean) => async (dispatch: Dispatch) => {
    dispatch(fileAttach({ chatId, conversationId, isFileAttached }));
  };
