import { configureStore } from "@reduxjs/toolkit";
import {
  userGetSlice,
  userLoginSlice,
  userRegisterSlice,
  userUpdatePasswordSlice,
  userUpdateProfileSlice,
  userVerifyEmailSlice,
} from "../features/user/userSlice";
import {
  createChatSlice,
  getAllChatsSlice,
  renameChatSlice,
  deleteChatSlice,
  createStreamSlice,
  getChatsPerIdSlice,
  fileAttach,
  fileAttachSlice,
} from "../features/chat/chatSlice";
export const store = configureStore({
  reducer: {
    // user reducers
    userLogin: userLoginSlice.reducer,
    userRegister: userRegisterSlice.reducer,
    userGet: userGetSlice.reducer,

    userUpdateProfile: userUpdateProfileSlice.reducer,
    userUpdatePassword: userUpdatePasswordSlice.reducer,
    userVerifyEmail:userVerifyEmailSlice.reducer,

    // chat reducers
    createChat: createChatSlice.reducer,
    getAllChats: getAllChatsSlice.reducer,
    getChatsPerId: getChatsPerIdSlice.reducer,
    renameChat: renameChatSlice.reducer,
    deleteChat: deleteChatSlice.reducer,
    createStream: createStreamSlice.reducer,
    fileAttach: fileAttachSlice.reducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
