import {
  ChatBubbleLeftIcon,
  TrashIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import {
  deleteChat,
  getAllChats,
  renameChat,
} from "../../features/chat/chatActions";

type ChatProps = {
  id: string;
  userId: string;
  chatName: string;
  createdAt: string;
  updatedAt: string;
};
function ChatRow({ chat }: { chat: ChatProps }) {
  const chatName = chat.chatName;
  const [newChatName, setNewChatName] = useState(chatName);
  const { chatId } = useParams();
  const active = chatId === chat.id;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [renameInputVisible, setRenameInputVisible] = useState(false);

  const [isHovered, setIsHovered] = useState(false);

  function removeChat(): void {
    if (chat.id) {
      dispatch(deleteChat(chat.id));
      dispatch(getAllChats(1));
    } else {
      console.log("chat not found");
    }

    if (chatId === chat.id) {
      navigate("/chats");
    }
  }
  const renameButtonHandler = () => {
    setRenameInputVisible(true);
    setIsHovered(true);
  };
  function setNewName(value: string, chatId: string): void {
    setNewChatName(value);
    dispatch(renameChat({ chatId, chatName: value }));
  }
const chatRenameEventHandler = (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();
  setRenameInputVisible(false);
  setNewName(newChatName, chat.id);
}
  return (
    <Link
      to={`/chat/${chat.id}`}
      className={`flex justify-around py-3 px-3 items-center gap-3 relative rounded-md hover:bg-[#2A2B32] cursor-pointer break-all hover:pr-4 group  ${
        active && "bg-neutral-700"
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      
      {isHovered ? (
        <PencilSquareIcon className="h-5 w-5 " onClick={renameButtonHandler} />
      ) : (
        <ChatBubbleLeftIcon
          className="h-5 w-5 "
          onClick={renameButtonHandler}
        />
      )}
      {renameInputVisible ? (
        <form onSubmit={chatRenameEventHandler}>
          <input
            className="bg-transparent rounded-lg w-full"
            type="text"
            value={newChatName}
            name="chatName"
            onChange={(e) => setNewChatName(e.target.value)}
          />
        </form>
      ) : (
        <p className="flex  flex-col text-(rgb(201, 237, 248)">
          {newChatName || "New Chat"}
        </p>
      )}
      <TrashIcon
        onClick={removeChat}
        className="h-5 w-5 text-white hover:text-red-700"
      />
    </Link>
  );
}

export default ChatRow;
