import React from "react";

export default function TypingLoadingIndicator() {
  return (
    <div className="flex justify-start items-center">
      <div className="flex space-x-2 pt-3">
        <div className="h-2 w-2 bg-gray-500 rounded-full animate-bounce [animation-delay:-0.25s]"></div>
        <div className="h-2 w-2 bg-gray-600 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
        <div className="h-2 w-2 bg-gray-700 rounded-full animate-bounce"></div>
      </div>
    </div>
  );
}
