import {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  ReactPortal,
  useEffect,
  useState,
} from "react";
import ChatRow from "./ChatRow";
import CreateChat from "./CreateChat";
import Loader from "../user/Loader";
import { useAppDispatch, useTypedSelector } from "../../app/hooks";
import { getAllChats } from "../../features/chat/chatActions";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import {
  ArrowRightStartOnRectangleIcon,
  Cog6ToothIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Profile from "../user/Profile";
import { logout } from "../../features/user/userActions";
import { useNavigate } from "react-router-dom";
import { JSX } from "react/jsx-runtime";
function SideBar({
  toggleMenu,
  setToggleMenu,
}: {
  toggleMenu: boolean;
  setToggleMenu: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const {
    chats: allChatsData,
    isLoading,
    error,
  } = useTypedSelector((state) => state.getAllChats);
  useEffect(() => {
    dispatch(getAllChats(1));
  }, []);
  const chats = allChatsData?.paginatedData;
  const logoutButtonHandler = () => {
    dispatch(logout());
    localStorage.removeItem("user");

    navigate("/login");
  };
  useEffect(() => {
    if (!allChatsData?.paginatedData) {
      toast.error("Something went wrong");
    }

    if (error) {
      toast.error("Something went wrong" + (error as AxiosError).message);
    }
  }, [error, chats]);

  // console.log(toggleMenu)
  return (
    <>
      <div
        className={`flex-shrink-0 bg-gray-900 ${
          toggleMenu ? "block" : "hidden"
        } md:flex md:w-[260px] md:flex-col`}
      >
        <div className="flex h-full min-h-0 flex-col ">
          {toggleMenu ? (
            <div className="relative z-40">
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75 opacity-100"></div>
              <div className="fixed inset-0 z-40 flex">
                <div className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-900 translate-x-0">

                  <div className="absolute top-0 right-0 -mr-12 pt-2 opacity-100">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setToggleMenu(!toggleMenu)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  
                  </div>
                   <div
                     
                     className="fixed z-[999] inset-4 pointer-events-none"
                     ></div>
                    <div
                     
                     className="fixed z-[999] inset-4 pointer-events-none"
                     ></div>
                     {sideBar(isLoading, chats, Logout)}
                </div>
                <div className="w-14 flex-shrink-0" ></div>
              </div>
            </div>
          ) : (
            <>{sideBar(isLoading, chats, Logout)}</>
          )}
        </div>
      </div>
    </>
  );

  function Logout() {
    return (
      <div
        className="flex py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-500/10 transition-colors duration-200 text-white cursor-pointer text-sm "
        onClick={logoutButtonHandler}
      >
        <ArrowRightStartOnRectangleIcon className="h-4 w-4" />
        Log out
      </div>
    );
  }
}

export default SideBar;

function sideBar(
  isLoading: boolean,
  chats: {
    id: string;
    userId: string;
    chatName: string;
    createdAt: string;
    updatedAt: string;
  }[],
  Logout: () => JSX.Element
) {
  return (
    <div className="scrollbar-trigger flex h-full w-full flex-1 items-start border-white/20 bg-[#2f2f2f]">
      <nav className="flex h-full flex-1 flex-col space-y-1 p-2">
        <CreateChat />

        <div className="flex-col flex-1 overflow-y-auto border-b border-white/20">
          <div className="flex flex-col gap-2 pb-2 text-gray-100 text-sm">
            {isLoading && <Loader />}

            {/* chatRow */}
            {chats?.length > 0
              ? chats?.map((chat) => <ChatRow key={chat.id} chat={chat} />)
              : null}
          </div>
        </div>

        {/* lower nav */}

        <Profile />

        {Logout()}
      </nav>
    </div>
  );
}
